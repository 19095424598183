import { types } from '../types/types';

const initialState = {
  cliente: [],
  activeCliente: false
};

export const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.clienteAddNew:
      return {
        ...state,
        cliente: [...state.cliente, action.payload]
      };

    default:
      return state;
  }
};
