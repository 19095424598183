import React, { useState } from 'react';
import {
  AppBar,
  //   Box,
  Card,
  CardContent,
  CardHeader,
  Icon,
  Paper,
  Tab,
  Typography
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalOffer as LocalOfferIcon } from '@mui/icons-material';
import { ProductoGeneral } from './ProductoGeneral';
import { ProductoMedida } from './ProductoMedida';
import { ProductoClasificacion } from './ProductoClasificacion';
import { ProductoLote } from './ProductoLote';

export const ProductoForm = () => {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Producto</Typography>}
          avatar={
            <Icon>
              <LocalOfferIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <TabContext value={value}>
            <AppBar position="static">
              {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
              <TabList
                onChange={handleChange}
                aria-label=" Tabs Products"
                variant="fullwidth"
                orientation="horizontal"
                indicatorColor="secondary"
                textColor="white"
              >
                <Tab label="Generales" value="1" />
                <Tab label="Medida" value="2" />
                <Tab label="Clasificación" value="3" />
                <Tab label="Lote / Caducidad" value="4" />
              </TabList>
              {/* </Box> */}
            </AppBar>
            <TabPanel value="1">
              <ProductoGeneral />
            </TabPanel>
            <TabPanel value="2"><ProductoMedida/></TabPanel>
            <TabPanel value="3"><ProductoClasificacion /></TabPanel>
            <TabPanel value="4"><ProductoLote/></TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </Paper>
  );
};
