import { types } from '../types/types';

const initialState = {
  proveedor: [],
  activeProveedor: false,
};

export const proveedorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.proveedorAddNew:
      return { 
          ...state, 
          proveedor: [...state.proveedor, action.payload] 
        };

    default:
      return state;
  }
};
