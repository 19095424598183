import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { gsap } from 'gsap';
import { Box, Container, styled, Typography } from '@mui/material';
import logo from '../assets/images/logo-orange.png';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

export const Dashboard = () => {
  const { nombre } = useSelector((state) => state.user);
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to('.appName', { y: -10, duration: 0.2, ease: 'ease.out' });
    tl.to('.appName', { y: 0, duration: 1, ease: 'bounce.out' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Panda</title>
      </Helmet>
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography className="appName" variant="h2" paragraph>
            Panda App
          </Typography>
          <Typography className="appName" variant="h3" sx={{ color: 'text.secondary' }} paragraph>
              {nombre}
          </Typography>
          <Box
            component="img"
            src={logo}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 5 } }}
            id="logo"
            className="appName"
          />
        </StyledContent>
      </Container>
    </>
  );
};
