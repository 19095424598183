import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { UnidadesForm } from '../../components/unidades/UnidadesForm';
import { UnidadesList } from '../../components/unidades/UnidadesList';

export const Unidades = () => (
  <>
    <Helmet>
      <title>Unidades Medida</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'Background.default',
        minHeight: '100%',
        marginTop: '20px',
        marginLeft: '0px'
      }}
    >
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <UnidadesForm />
          </Grid>
          <Grid item xs={12}>
            <UnidadesList />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);
