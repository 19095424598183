import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { fetchClientes } from '../../db/clientes';
import { reportsOptions } from '../../helpers/reportsOptions';

export const Table = () => {
  const [dataClient, setDataClient] = useState([]);

  useEffect(() => {
    const clientes = async () => {
      try {
        const resp = await fetchClientes('api/clientes');
        const body = await resp.json();

        if (body.ok) {
          setDataClient(body.data);
        }
      } catch (error) {
        setDataClient([]);
      }
    };

    clientes();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'nombre',
      label: 'Nombre',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'razon',
      label: 'Razón Social',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'domicilio',
      label: 'Domicilio',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'ciudad',
      label: 'Ciudad',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'estado',
      label: 'Estado',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'rfc',
      label: 'RFC',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'tel',
      label: 'Teléfono',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'contacto',
      label: 'Contacto',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'correo',
      label: 'Email',
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: 'cel',
      label: 'cel',
      options: {
        filter: true,
        sort: false
      }
    }
  ];

  const handleClientes = async () => {
    try {
      const resp = await fetchClientes('api/clientes');
      const body = await resp.json();

      if (body.ok) {
        setDataClient(body.data);
      }
    } catch (error) {
      setDataClient([]);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'Background.default',
          width: '95%',
          minHeight: '50%',
          marginTop: '40px',
          marginLeft: '10px'
        }}
        justifyContent="center"
        justifySelf="center"
      >
        <Button
          variant="contained"
          onClick={handleClientes}
          sx={{ marginBottom: '20px' }}
        >
          {' '}
          Clientes
        </Button>
        <MUIDataTable
          title="Clientes"
          data={dataClient}
          columns={columns}
          options={reportsOptions}
        />
      </Box>
    </>
  );
};
