import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Collapse,
  ListItemButton,
  ListItemText
} from '@mui/material';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import NavItem from './NavItem';
import { catalogos, operacion, reportes } from '../utils/routesMenu';

export const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  const [operacionOpen, setOperacionOpen] = useState(false);
  const [catOpen, setCatOpen] = useState(false);
  const [repoOpen, setRepoOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          <ListItemButton
            onClick={() => setOperacionOpen(!operacionOpen)}
            selected={operacionOpen}
            // sx={{ backgroundColor: 'rgba(38,53,118,1)', color: 'white' }}
          >
            <ListItemText primary="Operación" variant="h1" />
            {operacionOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={operacionOpen} timeout="auto">
            <List component="div" disablePadding>
              {operacion.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          </Collapse>
          <ListItemButton
            onClick={() => setCatOpen(!catOpen)}
            selected={catOpen}
          >
            <ListItemText primary="Catálogos" />
            {catOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={catOpen} timeout="auto">
            <List component="div" disablePadding>
              {catalogos.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          </Collapse>

          <ListItemButton
            onClick={() => setRepoOpen(!repoOpen)}
            selected={repoOpen}
          >
            <ListItemText primary="Reportes" />
            {repoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
          <Collapse in={repoOpen} timeout="auto">
            <List component="div" disablePadding>
              {reportes.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          </Collapse>
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: { width: 256 }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 108,
              height: 'calc(100% - 108px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};
