import React from 'react';
// import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import GlobalStyles from './components/GlobalStyles';
import { theme } from './theme';
import { Router } from './routes'
import history from './history';

export const AppRouter = () => (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Router history={history} />
        </ThemeProvider>
    );
