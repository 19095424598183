const baseUrl = process.env.REACT_APP_API_URL;


const fetchSinToken = (endpoint, data, method = 'GET') => {
    const url = `${baseUrl}/${endpoint}`;
  console.log('methodos', method);
    if (method === 'GET') {
      return fetch(url);
    }
  
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  };
  
  export {
    fetchSinToken
  }
