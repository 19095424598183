import React, {
  useState
  // useEffect
} from 'react';
import // useDispatch,
// useSelector
'react-redux';
import {
  // Icon,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  ArrowForward as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

export const ProductoClasificacion = () => {
  const initialForm = {
    clasificacion: '',
    cupo: '',
    rack: '',
    renglon: '',
    columna: '',
    obsoleto: ''
  };
  const [formValues, setFormValues] = useState(initialForm);
  const { clasificacion, cupo, rack, renglon, columna, obsoleto } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };
  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(usuarioAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Clasificación</Typography>}
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Clasificación"
                    placeholder="EE MP"
                    required
                    fullWidth
                    variant="outlined"
                    name="clasificacion"
                    value={clasificacion}
                    onChange={handleInputChange}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={obsoleto}
                          name="obsoleto"
                          onChange={handleInputChange}
                        />
                      }
                      label="Obsoleto"
                    />
                  </FormGroup>

                  <TextField
                    label="Capacidad Tarima"
                    fullWidth
                    variant="outlined"
                    name="cupo"
                    value={cupo}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Typography variant="h4">Ubicación</Typography>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Rack"
                    fullWidth
                    variant="outlined"
                    name="rack"
                    value={rack}
                    onChange={handleInputChange}
                  />

                  <TextField
                    label="Renglón"
                    fullWidth
                    variant="outlined"
                    name="renglon"
                    value={renglon}
                    onChange={handleInputChange}
                  />

                  <TextField
                    label="Columna"
                    variant="outlined"
                    fullWidth
                    name="columna"
                    value={columna}
                    onChange={handleInputChange}
                    type="number"
                  />
                </Stack>
              </Grid>

              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
