import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export const UnidadesList = () => {
  const { unidad } = useSelector((state) => state.unidad);

  const columns = [
    { name: 'nombre', label: 'Nombre', options: { filter: true, sort: true } },
    {
      name: 'descripcion',
      label: 'Descripción',
      options: { filter: true, sort: true }
    },
    {
      name: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: () => (
          <>
            <Button variant="contained" startIcon={<EditIcon />}>
              <Typography variant="text">Editar</Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<DeleteIcon />}
              sx={{ ml: '5px' }}
            >
              <Typography variant="text">Eliminar</Typography>
            </Button>
          </>
        )
      }
    }
  ];
  const data = [];
  data.push(...unidad);
  

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: '100%'
  };

  return (
    <MUIDataTable
      title={<Typography variant="h2">Listado Unidades Medida</Typography>}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
