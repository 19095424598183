import React, {
  useState
  // useEffect
} from 'react';
import {
  // useDispatch,
  useSelector
} from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  // Icon,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import {
  ArrowForward as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

export const ProductoMedida = () => {
  const { unidad } = useSelector((state) => state.unidad);

  const initialForm = {
    uMedida: '',
    uMedidaC: '',
    factor: ''
  };
  const [formValues, setFormValues] = useState(initialForm);
  const { uMedida, uMedidaC, factor } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };
  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(usuarioAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader title={<Typography variant="h2">Medida</Typography>} />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Unidad de Medida"                    
                    required
                    fullWidth
                    variant="outlined"
                    name="uMedida"
                    value={uMedida}
                    onChange={handleInputChange}
                    select
                  >
                    {unidad.map((option) => (
                      <MenuItem key={option.nombre} value={option.nombre}>
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Unida de Medida Conversión"
                    required
                    fullWidth
                    variant="outlined"
                    name="uMedidaC"
                    value={uMedidaC}
                    onChange={handleInputChange}
                    select
                  >
                    {unidad.map((option) => (
                      <MenuItem key={option.nombre} value={option.nombre}>
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Factor Conversión"
                    variant="outlined"
                    fullWidth
                    name="factor"
                    value={factor}
                    onChange={handleInputChange}
                    type="number"
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Siguiente
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
