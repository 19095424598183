import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  PeopleAlt as PeopleAltIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { clienteAddNew } from '../../actions/cliente';


const clientes = [
  {
    nomcom: 'Corvo Consultores',
    razsoc: 'Corvo Consultores S.A. de C.V.',
    rfc: 'RAZJ781021BV7',
    domicilio: 'Calle de la Paz #5',
    cp: '21050',
    tel: '686-8-00-00',
    nomcon: 'Jacinto Ramirez',
    email: 'jacinto.ramirez@corvoconsultores.com',
    cel: '6861139435'
  },
  {
    nomcom: 'Corvo Consultores',
    razsoc: 'Corvo Consultores S.A. de C.V.',
    rfc: 'RAZJ781021BV7',
    domicilio: 'Calle de la Paz #5',
    cp: '21050',
    tel: '686-8-00-00',
    nomcon: 'Jacinto Ramirez',
    email: 'jacinto.ramirez@corvoconsultores.com',
    cel: '6861139435'
  }
];

export const ClienteForm = () => {
  const dispatch = useDispatch();
  const { cliente } = useSelector((state) => state.cliente);

  const initialForm = {
    id_edo: '',
    id_ciudad: '',
    nomcom: '',
    razsoc: '',
    rfc: '',
    domicilio: '',
    cp: '',
    tel: '',
    nomcon: '',
    email: '',
    cel: ''
  };

  useEffect(() => {
    if (cliente.length === 0) {
      clientes.map((prov) => dispatch(clienteAddNew(prov)));
    }
  }, [dispatch]);

  const [formValues, setFormValues] = useState(initialForm);
  const {
    // id_edo,
    // id_ciudad,
    nomcom,
    razsoc,
    rfc,
    domicilio,
    cp,
    tel,
    nomcon,
    email,
    cel
  } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };

  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(clienteAddNew(formValues));
    reset();
  };

  const handleCancel = () => {
    reset();
  };

  

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Cliente</Typography>}
          avatar={
            <Icon>
              <PeopleAltIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Nombre Comercial"
                  name="nomcom"
                  value={nomcom}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Razón Social"
                    name="razsoc"
                    value={razsoc}
                    onChange={handleInputChange}
                  />
                  <TextField
                    fullWidth
                    label="RFC"
                    name="rfc"
                    value={rfc}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Domicilio"
                  name="domicilio"
                  value={domicilio}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Código Postal"
                    name="cp"
                    value={cp}
                    onChange={handleInputChange}
                  />
                  <TextField
                    fullWidth
                    label="Teléfono"
                    name="tel"
                    value={tel}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Nombre Contacto"
                  name="nomcon"
                  value={nomcon}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    fullWidth
                    label="Celular"
                    name="cel"
                    value={cel}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
