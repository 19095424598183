import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export const ProveedorList = () => {
  const { proveedor } = useSelector((state) => state.proveedor);

  const columns = [
    { name: 'idProveedor', label: 'Id', options: { filter: true, sort: true } },
    { name: 'nombre', label: 'Nombre', options: { filter: true, sort: true } },
    { name: 'domicilio', label: 'Domicilio', options: { filter: true, sort: true } },
    { name: 'telefono', label: 'Telefono', options: { filter: true, sort: true } },
    { name: 'contacto', label: 'Contacto', options: { filter: true, sort: true } },
    { name: 'email', label: 'Email', options: { filter: true, sort: true } },    
    {
      name: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: () => (
          <>
            <Button variant="contained" startIcon={<EditIcon />}>
              <Typography variant="text">Editar</Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<DeleteIcon />}
              sx={{ ml: '5px' }}
            >
              <Typography variant="text">Eliminar</Typography>
            </Button>
          </>
        )
      }
    }
  ];
  const data = [];
  data.push(...proveedor);
  console.log(data);

  const options = {
    filter: true,
    filterType: 'checkbox',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: '100%'
  };

  return (
    <MUIDataTable
      title={<Typography variant="h2">Listado Proveedores</Typography>}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
