import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';

export const EmpleadoList = () => {
  const { empleado } = useSelector((state) => state.empleado);

  const columns = [
    { name: 'nombre', label: 'Nombre', options: { filter: true, sort: true } },
    { name: 'idTipous', label: 'Tipo', options: { filter: true, sort: true } },
    {
      name: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: () => (
          <>
            <Button variant="contained" startIcon={<EditIcon />}>
              <Typography variant="text">Editar</Typography>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<DeleteIcon />}
              sx={{ ml: '5px' }}
            >
              <Typography variant="text">Eliminar</Typography>
            </Button>
          </>
        )
      }
    }
  ];

  const data = [];
  data.push(...empleado);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: '100%'
  };

  return (
    <MUIDataTable
      title={<Typography variant="h2">Listado Colaboradores</Typography>}
      data={data}
      columns={columns}
      options={options}
    />
  );
};
