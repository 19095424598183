import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { UsuarioForm } from '../../components/usuario/UsuarioForm'
import { UsuarioList } from '../../components/usuario/UsuarioList'

export const Usuarios = () => (
    <>
        <Helmet>
            <title>Usuarios</title>
        </Helmet>
        <Box
      sx={{
        backgroundColor: 'Background.default',
        minHeight: '100%',
        marginTop: '20px',
        marginLeft: '0px'
      }}
    >
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <UsuarioForm />
          </Grid>
          <Grid item xs={12}>
            <UsuarioList/>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
)
