import { combineReducers } from 'redux';
import { rackReducer } from './rackReducer';
import { unidadReducer } from './unidadReducer';
import { almacenReducer} from './almacenReducer';
import { plantaReducer } from './plantaReducer';
import { proveedorReducer } from './proveedorReducer';
import { usuarioReducer } from './usuarioReducer';
import { clienteReducer } from './clienteReducer';
import { empleadoReducer } from './empleadoReducer';
import { authReducer } from './authReducer';

export const rootReducer = combineReducers({
    user: authReducer,
    rack: rackReducer,
    unidad: unidadReducer,
    almacen: almacenReducer,
    planta: plantaReducer,
    proveedor: proveedorReducer,
    usuario: usuarioReducer,
    cliente: clienteReducer,
    empleado: empleadoReducer
});