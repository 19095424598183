import React from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

export const UsuarioList = () => {
    const { usuario } = useSelector((state) => state.usuario);
    
    const columns = [
      { name: 'nombre', label: 'Nombre', options: { filter: true, sort: true } },      
      { name: 'email', label: 'Email', options: { filter: true, sort: true } },
      { name: 'idTipous', label: 'Tipo', options: { filter: true, sort: true } },     
      {
        name: 'Acción',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: () => (
            <>
              <Button variant="contained" startIcon={<EditIcon />}>
                <Typography variant="text">Editar</Typography>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<DeleteIcon />}
                sx={{ ml: '5px' }}
              >
                <Typography variant="text">Eliminar</Typography>
              </Button>
            </>
          )
        }
      }
    ];
    const data = [];
    data.push(...usuario);
    console.log(data);
  
    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: '100%'
    };
  
    return (
      <MUIDataTable
        title={<Typography variant="h2">Listado Usuarios</Typography>}
        data={data}
        columns={columns}
        options={options}
      />
    );
}
