const baseUrl = process.env.REACT_APP_API_URL;

export const fetchClientes = async (endpoint) => {
  const url = `${baseUrl}/${endpoint}`;

  try {
    const getClientes = await fetch(url);

    return getClientes;
  } catch (error) {
    return error.message;
  }
};
