import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  LocalShipping as LocalShippingIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { proveedorAddNew } from '../../actions/proveedor';

const proveedores = [
  {
    idProveedor: '12',
    nombre: 'Proveedor1',
    domicilio: 'Conocido',
    telefono: '555-251-4567',
    contacto: 'contacto1',
    email: 'correo@empresa.com'
  },
  {
    idProveedor: '13',
    nombre: 'Proveedor2',
    domicilio: 'Conocido',
    telefono: '555-251-4567',
    contacto: 'contacto2',
    email: 'correo@empresa.com'
  }
];

export const ProveedorForm = () => {
  const dispatch = useDispatch();
  const { proveedor } = useSelector((state) => state.proveedor);

  const initialForm = {
    idProveedor: '',
    nombre: '',
    domicilio: '',
    telefono: '',
    contacto: '',
    email: ''
  };

  useEffect(() => {
    if (proveedor.length === 0) {
      proveedores.map((prov) => dispatch(proveedorAddNew(prov)));
    }
  }, [dispatch]);

  const [formValues, setFormValues] = useState(initialForm);
  const { idProveedor, nombre, domicilio, telefono, contacto, email } =
    formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };

  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(proveedorAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Proveedor</Typography>}
          avatar={
            <Icon>
              <LocalShippingIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="# Proveedor"
                  placeholder="123"
                  required
                  fullWidth
                  variant="outlined"
                  name="idProveedor"
                  value={idProveedor}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Nombre/Razón Social"
                  required
                  fullWidth
                  variant="outlined"
                  name="nombre"
                  value={nombre}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Domicilio"
                  variant="outlined"
                  fullWidth
                  name="domicilio"
                  value={domicilio}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Teléfono"
                  variant="outlined"
                  fullWidth
                  name="telefono"
                  value={telefono}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 10 }}
                  type="tel"
                />
              </Grid>
              <Grid item row xs={12} md={12} spacing={3}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="contactoId"
                    label="Contacto"
                    variant="outlined"
                    fullWidth
                    name="contacto"
                    value={contacto}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="emailId"
                    label="Correo Electrónico"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>              
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
