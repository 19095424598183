import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Paper,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button
} from '@mui/material';
import {
  Person as PersonIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { empleadoAddNew } from '../../actions/empleado';

const empleados = [
  {
    nombre: 'Empleado 1',
    idTipous: 'admin'
  },
  {
    nombre: 'Empleado 2',
    idTipous: 'impresion'
  }
];

const tipos = [
  { value: 'admin', label: 'Administrador' },
  { value: 'impresion', label: 'Impresión' },
  { value: 'taller', label: 'Taller' },
  { value: 'instalacion', label: 'Instalación' },
  { value: 'entrega', label: 'Entrega' },
  { value: 'cxc', label: 'CxC' },
  { value: 'ordenTrabajo', label: 'Orden de Trabajo' }
];

export const EmpleadoForm = () => {
  const dispatch = useDispatch();
  const { empleado } = useSelector((state) => state.empleado);

  const initialForm = {
    nombre: '',
    idTipous: ''
  };

  useEffect(() => {
    if (empleado.length === 0) {
      empleados.map((prov) => dispatch(empleadoAddNew(prov)));
    }
  }, [dispatch]);

  const [formValues, setFormValues] = React.useState(initialForm);
  const { nombre, idTipous } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };

  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(empleadoAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Empleado</Typography>}
          avatar={
            <Icon>
              <PersonIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Nombre"
                    required
                    fullWidth
                    variant="outlined"
                    name="nombre"
                    value={nombre}
                    onChange={handleInputChange}
                  />
                  <TextField
                    id="tipoId"
                    label="Tipo usuario"
                    variant="outlined"
                    fullWidth
                    name="idTipous"
                    value={idTipous}
                    onChange={handleInputChange}
                    select
                  >
                    {tipos.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
