import {
  Assignment as AssigmentIcon,
  GroupAdd as GroupAddIcon,
  // LocalOffer as LocalOfferIcon,
  LocalShipping as LocalShippingIcon,
  SquareFoot as SquareFootIcon,
  PeopleAlt as PeopleAltIcon,
  Person as PersonIcon,
} from '@mui/icons-material';

export const catalogos = [
  {
    href: '/app/clientes',
    icon: PeopleAltIcon,
    title: 'Clientes'
  },
  {
    href: '/app/empleados',
    icon: PersonIcon,
    title: 'Colaboradores'
  },
  // {
  //   href: '/app/productos',
  //   icon: LocalOfferIcon,
  //   title: 'Productos'
  // },  
  {
    href: '/app/unidades',
    icon: SquareFootIcon,
    title: 'Unidad Medida'
  }, 
  {
    href: '/app/proveedores',
    icon: LocalShippingIcon,
    title: 'Proveedores'
  },
  {
    href: '/app/usuarios',
    icon: GroupAddIcon,
    title: 'Usuarios'
  }
];

export const operacion = [
  {
    href: '/app/ordenes',
    icon: AssigmentIcon,
    title: 'Ordenes Trabajo'
  }
];

export const reportes = [
  {
    href: '/app/tabla',
    icon: AssigmentIcon,
    title: 'Clientes'
  },
  {
    href: '/app/tabla2',
    icon: AssigmentIcon,
    title: 'Tabla2'
  }
];
