import React, {
  useState
  // useEffect
} from 'react';
import // useDispatch,
// useSelector
'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  // Icon,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  ArrowForward as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

export const ProductoLote = () => {
  const initialForm = {
    costo: '',
    lote: '',
    fechaCad: '',
    maximo: '',
    minimo: '',
    reorden: ''
  };
  const [formValues, setFormValues] = useState(initialForm);
  const { costo, lote, fechaCad, maximo, minimo, reorden } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };
  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(usuarioAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader title={<Typography variant="h2">Generales</Typography>} />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Costo Item"
                    fullWidth
                    variant="outlined"
                    name="costo"
                    value={costo}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Fecha Caducidad"
                    fullWidth
                    variant="outlined"
                    name="fechaCad"
                    value={fechaCad}
                    onChange={handleInputChange}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    label="Lote"
                    variant="outlined"
                    fullWidth
                    name="lote"
                    value={lote}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Máximo"
                    fullWidth
                    variant="outlined"
                    name="maximo"
                    value={maximo}
                    onChange={handleInputChange}
                    type="number"
                  />
                  <TextField
                    label="Mínimo"
                    fullWidth
                    variant="outlined"
                    name="minimo"
                    value={minimo}
                    onChange={handleInputChange}
                    type="number"
                  />

                  <TextField
                    label="Reorden"
                    variant="outlined"
                    fullWidth
                    name="reorden"
                    value={reorden}
                    onChange={handleInputChange}
                    type="number"
                  />
                </Stack>
              </Grid>

              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
