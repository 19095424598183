import React from 'react';
import {
  Avatar,
  CssBaseline,
  Box,
  Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme';
import { Logo } from '../../components/Logo';


export const Error= () => (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Logo width="70%" height="70%" color="negro" />
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            404
          </Typography>
          <Box
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography component="h1" variant="h5">
            Página no encontrada
          </Typography>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );