// import logo from '../assets/images/logo-orange.png';
import logoSolo from '../assets/images/logo-orangeSolo.png';
import logoNegro from '../assets/images/logo.png';

export const Logo = (props) => {
  const { color } = props;
  if (color === 'naranja') {
    return <img alt="Logo" src={logoSolo} {...props} />;
  }
  return <img alt="Logo" src={logoNegro} {...props} />;
};
