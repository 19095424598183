import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ProductoForm } from '../../components/productos/ProductoForm';

export const Productos = () => (
  <>
    <Helmet>
      <title>Productos</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'Background.default',
        minHeight: '100%',
        marginTop: '20px',
        marginLeft: '0px'
      }}
    >
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <ProductoForm />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Lista de productos</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);
