import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { EmpleadoForm } from '../../components/empleados/EmpleadoForm';
import { EmpleadoList } from '../../components/empleados/EmpleadoList';

export const Empleados = () => (
  <>
    <Helmet>
      <title>Empleados</title>
    </Helmet>
    <Box
    sx={{
        backgroundColor: 'Background.default',
        mb: 5,
        minHeight: '100%',
        marginTop: '20px',
        marginLeft: '0px'
    }}
    >
        <Container maxWidth="xl">
            <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                <Grid item xs={12}>
                    <EmpleadoForm />
                </Grid>
                <Grid item xs={12}>
                    <EmpleadoList />
                </Grid>
            </Grid>
            </Container>

    </Box>
  </>
);
