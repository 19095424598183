import { Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Orders = () => (
  <>
    <Helmet>
      <title>Ordenes</title>
    </Helmet>
    <Typography variant="h1" color="primary">
      Ordenes
    </Typography>
  </>
);
