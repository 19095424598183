export const types = {
  authCheckingFinish: '[auth] Finish checking login state',
  authStartLogin: '[auth] Start login',
  authLogin: '[auth] Login',
  authStartRegister: '[auth] Start Register',
  authStartTokenRenew: '[auth] Start Token Renew',
  authLogout: '[auth] Logout',
  
  /** RACKS */
  rackStartAddNew: '[rack] Start add new',
  rackAddNew: '[rack] Add new',
  rackSetActive: '[rack] set active',
  rackCleanActive: '[rack] clean active',
  rackUpdated: '[rack] updated',
  rackDeleted: '[rack] deleted',
  rackLoaded: '[rack] loaded',

  /** ALMACÉN */
  almacenStartAddNew: '[almacen] Start add new',
  almacenAddNew: '[almacen] Add new',
  almacenSetActive: '[almacen] set active',
  almacenCleanActive: '[almacen] clean active',
  almacenUpdated: '[almacen] updated',
  almacenDeleted: '[almacen] deleted',
  almacenLoaded: '[almacen] loaded',

  /** UNIDAD DE MEDIDA */
  unidadStartAddNew: '[unidad] Start add new',
  unidadAddNew: '[unidad] Add new',
  unidadSetActive: '[unidad] set active',
  unidadCleanActive: '[unidad] clean active',
  unidadUpdated: '[unidad] updated',
  unidadDeleted: '[unidad] deleted',
  unidadLoaded: '[unidad] loaded',

  /**  PLANTA */
  plantaStartAddNew: '[planta] Start add new',
  plantaAddNew: '[planta] Add new',
  plantaSetActive: '[planta] set active',
  plantaCleanActive: '[planta] clean active',
  plantaUpdated: '[planta] updated',
  plantaDeleted: '[planta] deleted',
  plantaLoaded: '[planta] loaded',

  /** PROVEEDOR */
  proveedorStartAddNew: '[proveedor] Start add new',
  proveedorAddNew: '[proveedor] Add new',
  proveedorSetActive: '[proveedor] set active',
  proveedorCleanActive: '[proveedor] clean active',
  proveedorUpdated: '[proveedor] updated',
  proveedorDeleted: '[proveedor] deleted',
  proveedorLoaded: '[proveedor] loaded',

  /** USUARIO */
  usuarioStartAddNew: '[usuario] Start add new',
  usuarioAddNew: '[usuario] Add new',
  usuarioSetActive: '[usuario] set active',
  usuarioCleanActive: '[usuario] clean active',
  usuarioUpdated: '[usuario] updated',
  usuarioDeleted: '[usuario] deleted',
  usuarioLoaded: '[usuario] loaded',

  /** CLIENTES */
  clienteStartAddNew: '[cliente] Start add new',
  clienteAddNew: '[cliente] Add new',
  clienteSetActive: '[cliente] set active',
  clienteCleanActive: '[cliente] clean active',
  clienteUpdated: '[cliente] updated',
  clienteDeleted: '[cliente] deleted',
  clienteLoaded: '[cliente] loaded',

  /** EMPLEADOS */
  empleadoStartAddNew: '[empleado] Start add new',
  empleadoAddNew: '[empleado] Add new',
  empleadoSetActive: '[empleado] set active',
  empleadoCleanActive: '[empleado] clean active',
  empleadoUpdated: '[empleado] updated',
  empleadoDeleted: '[empleado] deleted',
  empleadoLoaded: '[empleado] loaded'
};
