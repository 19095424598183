export const reportsOptions = {
    filter: true,
    filterType: 'multiselect',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 100],
    tableBodyHeight: '500px',
    draggableColumns: {
      enabled: true
    },
    // onViewColumnsChange: (column, action) => {
    //   if (action === 'add' && column === 'des') {
    //     setAllCols(true);
    //     setDesSelect(true);
    //   } else if (action === 'remove' && column === 'des') {
    //     setAllCols(false);
    //     setDesSelect(false);
    //   }
    // },
    downloadOptions: {
      filename: 'padronUnico.csv',
      // separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    onDownload: (buildHead, buildBody, columns, data) =>
      `\uFEFF ${buildHead(columns)} ${buildBody(data)}`,
    print: false,
    textLabels: {
      body: {
        noMatch: 'Lo sentimos, no se encontraron registros',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Orden por ${column.label}`
      },
      pagination: {
        next: 'Página Siguiente',
        previous: 'Página Anterior',
        rowsPerPage: 'Renglones por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Print',
        viewColumns: 'Vista Columnas',
        filterTable: 'Filtros'
      },
      filter: {
        all: 'TODOS',
        title: 'FILTROS',
        reset: 'RESET'
      },
      viewColumns: {
        title: 'Columnas',
        titleAria: 'Show/Hide Table Columns'
      },
      selectedRows: {
        text: 'renglón(es) seleccionados',
        delete: 'Borrar',
        deleteAria: 'Borrar renglón(es) seleccionados'
      }
    }
  };