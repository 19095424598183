import { types } from '../types/types';

const initialState = {
  empleado: [],
  activeEmpleado: false
};

export const empleadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.empleadoAddNew:
      return {
        ...state,
        empleado: [...state.empleado, action.payload]
      };

    default:
      return state;
  }
};
