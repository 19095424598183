import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import {
  GroupAdd as GroupAddIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { usuarioAddNew } from '../../actions/usuario';

const usuarios = [
  {
    nombre: 'Usuario 1',
    email: 'usuario1@pandaimagen.com.mx',
    pass: '123456',
    idTipous: 'admin'
  },
  {
    nombre: 'Usuario 2',
    email: 'usuario2@pandaimagen.com.mx',
    pass: '123456',
    idTipous: 'produccion'
  }
];

const tipos = [
  { value: 'admin', label: 'Administrador' },
  { value: 'impresion', label: 'Impresión' },
  { value: 'taller', label: 'Taller' },
  { value: 'instalacion', label: 'Instalación' },
  { value: 'entrega', label: 'Entrega' },
  { value: 'cxc', label: 'CxC' },
  { value: 'ordenTrabajo', label: 'Orden de Trabajo' }
];

export const UsuarioForm = () => {
  const dispatch = useDispatch();
  const { usuario } = useSelector((state) => state.usuario);

  const initialForm = {
    idTipous: '',
    nombre: '',
    email: '',
    pass: ''
  };

  useEffect(() => {
    if (usuario.length === 0) {
      usuarios.map((prov) => dispatch(usuarioAddNew(prov)));
    }
  }, [dispatch]);

  const [formValues, setFormValues] = useState(initialForm);
  const { idTipous, email, nombre, pass } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };

  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(usuarioAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Usuario</Typography>}
          avatar={
            <Icon>
              <GroupAddIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    label="Nombre"
                    required
                    fullWidth
                    variant="outlined"
                    name="nombre"
                    value={nombre}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Email"
                    required
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12} spacing={3}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="cotrasenaId"
                    label="Contraseña"
                    variant="outlined"
                    fullWidth
                    name="pass"
                    value={pass}
                    onChange={handleInputChange}
                    type="password"
                  />
                  <TextField
                    id="tipoId"
                    label="Tipo usuario"
                    variant="outlined"
                    fullWidth
                    name="idTipous"
                    value={idTipous}
                    onChange={handleInputChange}
                    select
                  >
                    {tipos.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
