import { types } from '../types/types';

const initialState = {
  usuario: [],
  activeUsuario: false,
};

export const usuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.usuarioAddNew:
      return { 
          ...state, 
          usuario: [...state.usuario, action.payload] 
        };

    default:
      return state;
  }
};
