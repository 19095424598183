import { useSelector } from 'react-redux';
import { useRoutes, Navigate } from 'react-router-dom';
import { DashboardLayout } from './components/DashboardLayout';
import { Orders } from './pages/Orders';
import {
  Usuarios,
  Unidades,
  Proveedor,
  Productos,
  Clientes,
  Empleados,
} from './pages/catalogos/index';
import { Table2 } from './pages/reportes/Table2';
import { Table } from './pages/reportes/Table';
import SignIn from './components/auth/Login';
import { Error } from './pages/NotFound/Error';
import { Dashboard } from './pages/Dashboard';

export const Router = () =>{

  const {uid} = useSelector((state) => state.user);

  return useRoutes([
    {
      path: '/',
      element: !uid ? <SignIn /> : <Navigate to="/app/dashboard" />,
      children: [{ path: "login", element: <SignIn /> }
      //     { path: "ordenes", element: <Orders /> },
      //     { path: "usuarios", element: <Usuarios />},
      //     { path: "almacenes", element: <Almacenes />},
      ]
    },
    {
      path: 'app',
      element: uid ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'ordenes', element: <Orders /> },
        { path: 'productos', element: <Productos /> },
        { path: 'clientes', element: <Clientes /> },
        { path: 'empleados', element: <Empleados /> },
        { path: 'usuarios', element: <Usuarios /> },
        { path: 'unidades', element: <Unidades /> },
        { path: 'proveedores', element: <Proveedor /> },
        { path: 'tabla', element: <Table /> },
        { path: 'tabla2', element: <Table2 /> }
      ]
    },
    { path: '*', element: <Error /> }
  ]);
}