import React, {
  useState
  // useEffect
} from 'react';
import {
  // useDispatch,
  useSelector
} from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  //   Icon,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem
} from '@mui/material';
import {
  ArrowForward as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';

export const ProductoGeneral = () => {
  const { proveedor } = useSelector((state) => state.proveedor);

  const initialForm = {
    codigo: '',
    idProveedor: '',
    item: '',
    descripcion: ''
  };
  const [formValues, setFormValues] = useState(initialForm);
  const { codigo, idProveedor, item, descripcion } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };
  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(usuarioAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader title={<Typography variant="h2">Generales</Typography>} />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Item"
                  placeholder="123"
                  required
                  fullWidth
                  variant="outlined"
                  name="item"
                  value={item}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Código de barras"
                  required
                  fullWidth
                  variant="outlined"
                  name="codigo"
                  value={codigo}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Descripción"
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  value={descripcion}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  id="tipoId"
                  label="Proveedor"
                  variant="outlined"
                  fullWidth
                  name="idProveedor"
                  value={idProveedor}
                  onChange={handleInputChange}
                  select
                >
                  {proveedor.map((option) => (
                    <MenuItem
                      key={option.idProveedor}
                      value={option.idProveedor}
                    >
                      {option.idProveedor} - {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Siguiente
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
