import { types } from '../types/types';

const initialState = {
  planta: [],
  activePlanta: false,
};

export const plantaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.plantaAddNew:
      return { 
          ...state, 
          planta: [...state.planta, action.payload] 
        };

    default:
      return state;
  }
};
