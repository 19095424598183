import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {
  SquareFoot as SquareFootIcon,
  Save as SaveIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { unidadAddNew } from '../../actions/unidad';

const unidadesI = [
  { nombre: 'PZA', descripcion: 'Pieza' },
  { nombre: 'CJ', descripcion: 'Caja' }
];

export const UnidadesForm = () => {
  const dispatch = useDispatch();
  const { unidad } = useSelector((state) => state.unidad);

  const initialForm = {
    nombre: '',
    descripcion: ''
  };

  useEffect(() => {
    if (unidad.length === 0) {
      unidadesI.map((uni) => dispatch(unidadAddNew(uni)));
    }
  }, [dispatch]);

  const [formValues, setFormValues] = useState(initialForm);
  const { nombre, descripcion } = formValues;

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value
    });
  };

  const reset = () => {
    setFormValues(initialForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(unidadAddNew(formValues));

    reset();
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Paper elevation={3}>
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={<Typography variant="h2">Unidad de Medida</Typography>}
          avatar={
            <Icon>
              <SquareFootIcon />
            </Icon>
          }
        />
        <CardContent sx={{ width: '100%' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Nombre corto unidad"
                  placeholder="KG"
                  required
                  fullWidth
                  variant="outlined"
                  name="nombre"
                  value={nombre}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <TextField
                  label="Descripción de Unidad"
                  placeholder="Kilogramo"
                  required
                  variant="outlined"
                  fullWidth
                  name="descripcion"
                  value={descripcion}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item row xs={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Paper>
  );
};
