import { types } from '../types/types';

const initialState = {
  unidad: [],
  activeUnidad: false,
};

export const unidadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.unidadAddNew:
      return { 
          ...state, 
          unidad: [...state.unidad, action.payload] 
        };

    default:
      return state;
  }
};
