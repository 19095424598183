import Swal from 'sweetalert2';
import { fetchSinToken } from "../helpers/fetch";
import { types } from '../types/types';


const login = (user) => ({
    type: types.authLogin,
    payload: user,
});

const logout = () => ({ type: types.authLogout });

// const checkingFinish = () => ({ type: types.authCheckingFinish });

export const startLogin = (email, pass) => async (dispatch) => {
    try {
        const resp = await fetchSinToken('auth', { email, pass }, 'POST');
        const body = await resp.json();
        
        if (body.ok) {
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                nombre: body.nombre,
                tipo: body.tipo,
                user: body.user,
                uid: body.id,
            }))
        } else {
            Swal.fire('Error', 'Usuario y/o contraseña incorrectos', 'error');
        }
    } catch (error) {
        Swal.fire('Error', 'Usuario y/o contraseña incorrectos', 'error');
    }
}

// export const startChecking = () => async (dispatch) => {
//     try {
//         const token = localStorage.getItem('token');
//         const resp = await fetchConToken('renew', token);
//         const body = await resp.json();
        
//         if (body.ok && body.usuario.tipoApp === tipoApp) {
//             localStorage.setItem('token', body.token);
//             localStorage.setItem('token-init-date', new Date().getTime());

//             dispatch(login({
//                 nombre: body.nombre,
//                 tipo: body.tipo,
//                 uid: body.id
//             }))
//         }
//         else {
//             localStorage.clear();
//             dispatch(checkingFinish());
//         }
//     } catch (error) {
//         console.log(error)
//     }
// }


export const startLogout = (dispatch) => {
    localStorage.clear();
    dispatch(logout());
}