import { types } from '../types/types';

const initialState = {
  rack: [],
  activeRack: false,
};

export const rackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.rackAddNew:
      return { 
          ...state, 
          rack: [...state.rack, action.payload] 
        };

    default:
      return state;
  }
};
