import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import { shadows } from './shadows';
import { typography } from './typography';

// const naranja = '#e87918';
const naranja = '#f68122';
const negro = '#000000';

export const theme = createTheme({
    palette: {
        background: {
            paper: colors.common.white,
            default: '#F4F6F8'
        },
        primary: {
            contrastText: '#ffffff',
            main: naranja,
        },
        secondary: {
            contrastText: '#ffffff',
            main: negro,
        },
        text: {
            primary: '#172b4d',
            secondary: '#6b778c'
        }
    },
    shadows,
    typography
});

// export default theme;