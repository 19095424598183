import React from 'react';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

export const ClienteList = () => {
  const { cliente } = useSelector((state) => state.cliente);

  const columns = [
    {
      name: 'nomcom',
      label: 'Nombre Comercial',
      options: { filter: true, sort: true }
    },
    {
      name: 'razsoc',
      label: 'Razón Social',
      options: { filter: true, sort: true }
    },
    { name: 'rfc', label: 'RFC', options: { filter: true, sort: true } },
    {
      name: 'domicilio',
      label: 'Domicilio',
      options: { filter: true, sort: true }
    },
    {
      name: 'cp',
      label: 'Código Postal',
      options: { filter: true, sort: true }
    },
    { name: 'tel', label: 'Teléfono', options: { filter: true, sort: true } },
    {
      name: 'nomcon',
      label: 'Nombre Contacto',
      options: { filter: true, sort: true }
    },
    {
      name: 'Acción',
      options: {
        filter: false,

        sort: false,
        empty: true,
        customBodyRenderLite: () => (
          <>
            <Button variant="contained" startIcon={<EditIcon />}>
              <Typography variant="text">Editar</Typography>
            </Button>

            <Button
              variant="contained"
              color="secondary"
              endIcon={<DeleteIcon />}
              sx={{ ml: '5px' }}
            >
              <Typography variant="text">Eliminar</Typography>
            </Button>
          </>
        )
      }
    }
  ];

  const data = [];
  data.push(...cliente);
  console.log(data);

  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: '100%'
  };

  return (
    <MUIDataTable
    title={<Typography variant="h2">Listado Clientes</Typography>}
    data={data}
    columns={columns}
    options={options}
  />
  )
};
